<template>
  <div class="applyProductCombo-container">
    <div class="comboList register_cs">
      <div class="header2">
        <van-nav-bar title="商家注册" left-text="返回" left-arrow @click-left="onClickLeft">
          <template #right>
            <van-button class="submitBtn" @click="setSubmit" v-if="flags">提交注册</van-button>
            <van-button class="submitBtn" disabled v-else>提交注册</van-button>
          </template>
        </van-nav-bar>
      </div>
      <van-form validate-first autocomplete="off" class="regisForm_cs">

        <van-cell class="file_cs">
            <template #title>
                营业执照: <span style="color: red;"> *</span>
            </template>
            <el-upload
              style="display: inline-block; padding-left: 18px"
              :limit="max"
              :action="
                $store.state.imgUpdateUrl +
                '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
              "
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
              :file-list="merchantRow.businessLicensePics"
              :on-success="
                (response) => {
                  return merchantRowUpdateFlie1(response);
                }
              "
              :on-exceed="fnmax"
              :on-remove="
                (file, fileList) => {
                  return merchantHandleRemove1(file, fileList);
                }
              "
            >
            <i class="el-icon-plus"></i>
          </el-upload>
        </van-cell>

        <div class="form_cs">
          <van-cell-group>
              <van-cell title="商家名称: " :value="business.merchantName">
                <template #title>
                    商家名称: <span style="color: red;"> *</span>
                </template>
                <van-field v-model="business.merchantName" v-if="!business.merchantName" disabled maxlength="10" placeholder="上传营业执照成功后自动填入..."/>
                <van-field v-model="business.merchantName" v-else readonly maxlength="10" placeholder="上传营业执照成功后自动填入..."/>
              </van-cell>
          </van-cell-group>
          <van-cell-group>
              <van-cell title="社会信用代码: " :value="business.merchantCode">
                <template #title>
                    社会信用码: <span style="color: red;"> *</span>
                </template>
                <van-field v-model="business.merchantCode" v-if="!business.merchantCode" disabled maxlength="10" placeholder="上传营业执照成功后自动填入..."/>
                <van-field v-model="business.merchantCode" v-else readonly maxlength="10" placeholder="上传营业执照成功后自动填入..."/>
              </van-cell>
          </van-cell-group>
          <van-cell-group>
              <van-cell title="法人:" >
                <template #title>
                    法人: <span style="color: red;"> *</span>
                </template>
                  <van-field v-model="business.person" maxlength="10" placeholder="请输入法人"/>
              </van-cell>
          </van-cell-group>
          <van-cell-group>
            <van-cell title="联系人名称: ">
                <template #title>
                    联系人名称: <span style="color: red;"> *</span>
                </template>
              <van-field v-model="business.managerName" maxlength="10" placeholder="请输入联系人名称"/>
            </van-cell>
          </van-cell-group>
          <van-cell-group>
            <van-cell title="联系人手机: ">
                <template #title>
                    联系人手机: <span style="color: red;"> *</span>
                </template>
              <van-field v-model="business.managerPhone" type="text"  autocomplete="off" maxlength="11" placeholder="请输入联系人手机号"/>
            </van-cell>
          </van-cell-group>
          <van-cell-group>
            <van-cell title="登录密码: ">
                <template #title>
                    登录密码: <span style="color: red;"> *</span>
                </template>
              <van-field v-model="editpwdForm.newpwd" :type="passwordOff1?'password':'text'" autocomplete="new-password" maxlength="10" placeholder='请输入密码' @input="$emit('input',password)">
                <template slot="right-icon">
                  <img :src="passwordOff1?require('../../assets/img/eye-off.png'):require('../../assets/img/eye-on.png')"  width="20px"  alt="" @click="passwordOff1=!passwordOff1">
                </template>
              </van-field>
            </van-cell>
          </van-cell-group>
          <van-cell-group>
            <van-cell title="确认密码: ">
                <template #title>
                    确认密码: <span style="color: red;"> *</span>
                </template>
              <van-field v-model="editpwdForm.newpwd2" :type="passwordOff2?'password':'text'" autocomplete="new-password" maxlength="10" placeholder='请确认密码' @input="$emit('input',password)">
                <template slot="right-icon">
                  <img :src="passwordOff2?require('../../assets/img/eye-off.png'):require('../../assets/img/eye-on.png')" width="20px" alt="" @click="passwordOff2=!passwordOff2">
                </template>
              </van-field>
              </van-cell>
          </van-cell-group>
        </div>

        <!-- <p style="text-align: center; margin-top: 23vh;">
          <van-checkbox v-model="checked"></van-checkbox>同意《<span @click="showProtocol" style="color: blue; font-size: 18px;display: inline-block;vertical-align: middle; ">************</span>》协议内容
        </p> -->
        <van-popup
            v-model="show"
            closeable
            close-icon-position="top-left"
            position="bottom"
            :style="{ height: '60%' }"
          />
          <!-- 右滑进入 -->
          <transition name="van-slide-right">
            <div v-show="visible" class="right_cs">请先同意《注册使用协议》内容</div>
          </transition>
      </van-form>
        <div class="register_btn">

        </div>
    </div>
  </div>
</template>

<script>
import { Empty, Notify } from 'vant'
let telReg = /^1[3,4,5,6,7,8,9]{1}\d{9}$/
let posswordReg = /^(\w){6,10}$/ //只能输入6-18位字母、数字、下划线
export default {
    model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      flags: true,
      checked: true,
      visible: false,
      show: false,
      business:{
         merchantName: '',
         merchantCode: '',
         person: '',
         managerName:'',
         managerPhone:'',
      },
      editpwdForm:{
        newpwd: '',
        newpwd2: '',
      },
      password: this.inputValue,
      passwordType: 'password',
      passwordOff1: true,
      passwordOff2: true,
      merchantRow:{
      businessLicensePics:[],
    },



      forms: {
        flags: true,
      },
      imgType: ['image/jpg','image/jpeg','image/png','image/webp'],
      max: 1,
      productTypes: [],
      managerList: [],
    }
  },
  created() {
  },
  methods: {
    // 查看协议
    showProtocol(){
      this.show = true;
    },
        // 上传营业执照（供应商）
    merchantRowUpdateFlie1(response){
        this.$http
            .ajax(
                  '/ffkj-main/merchant/getBusinessLicense',
                {
                    imgUlr: response
                },
                'get',
                {}
              )
              .then((res) => {
                  if(res.msg =="营业执照识别成功"){
                    Notify({ type: 'success', message: res.msg, duration: 700 });
                    let businessVo = res.body.businessLicenseVo;
                    this.business.merchantName = businessVo.companyName;
                    this.business.merchantCode = businessVo.code;
                    this.business.person = businessVo.legalPerson;
                    this.merchantRow.businessLicensePics.push({url:response})
                  }else{
                    this.business.merchantName = '',
                    this.business.merchantCode = '',
                    this.business.person = '',
                    Notify({ type: 'danger', message: res.msg, duration: 1000 });
                    // this.$message.error(res.msg);
                    this.merchantHandleRemove1();
                  }

              }
            )
    },
    // 删除营业执照（供应商）
    merchantHandleRemove1(file, fileList){
        this.merchantRow.businessLicensePics = []
        this.business.merchantName = ''
        this.business.merchantCode = ''
        this.business.person = ''
    },
    // 取消注册
    onClickLeft() {
      this.$router.push({
        path: '/',
        query: {}
      })
    },

    onRead(file) {
      // 上传图片到图片服务器
      this.postData.push(file) // postData是一个数组
      fd.append('upImgs', file.file)
    },

    // 最多上传数量
    fnmax(el) {
      Notify({ type: 'danger', message: '最多允许上传' + this.max + '张' })
    },
    // 上传营业执照
    productRowUpdateFlie2(response) {
      this.fileList.push({ url: response })
    },
    beforeAvatarUpload(file) {
      if ( this.imgType.indexOf(file.type) == -1 ) {
          Notify({ type: 'danger', message: '上传图片只能是JPG/JPEG/PNG/webp格式',duration: 1500});
          return false
      }
      if ( file.size / 1024 / 1024 > 50 ) {
          Notify({ type: 'danger', message: '上传图片大小不能超过50MB',duration: 1500});
          return false
      }
    },
    // 提交注册
    setSubmit() {
      if(!this.checked){
          this.visible = true;
          setTimeout(() => {
            this.visible = false;
          }, 1000);
      }else{
         if(this.merchantRow.businessLicensePics.length == 0 ){
            Notify({ type: 'danger', message: '请上传营业执照' });
            return false;
         }
        if(this.business.person == ''){
            Notify({ type: 'danger', message: '法人姓名不能为空' });
            return false;
         }
         if(this.business.merchantName == ''|| this.business.merchantCode == '' || this.business.person == ''){
            Notify({ type: 'danger', message: '无法识别营业执照, 请重新上传 ! ! !' });
            return false;
         }
         if(this.business.managerName == ''){
            Notify({ type: 'danger', message: '请输入联系人姓名' });
            return false;
         }
         if(this.business.managerPhone == ''){
            Notify({ type: 'danger', message: '请输入联系人电话' });
            return false;
         }
        if (!telReg.test(this.business.managerPhone)) {
          Notify({ type: 'danger', message: '手机号格式错误' })
          return false
        }
         if(this.editpwdForm.newpwd == '' || this.editpwdForm.newpwd2 == ''){
            Notify({ type: 'danger', message: '请输入登录密码' });
            return false;
         }
        if(this.editpwdForm.newpwd != this.editpwdForm.newpwd2){
            Notify({ type: 'danger', message: '两次密码不一致,请重新输入!!!' });
            return false;
        }
        if (!posswordReg.test(this.editpwdForm.newpwd)) {
          Notify({ type: 'danger', message: '密码只能输入6-10位包含字母、数字、下划线' })
          return false
        }
        this.flags = false;
         this.$http
            .ajax(
                  '/ffkj-main/merchant/merchantRegister',
                {
                    businessLicensePic: this.merchantRow.businessLicensePics[0].url,//营业执照
                    cods: this.business.merchantCode,//统一社会信用代码
                    contacts: this.business.managerName,//商家联系人
                    legalPersonName: this.business.person,//法人
                    merchantName: this.business.merchantName,//商家名称
                    phone: this.business.managerPhone,//商家手机号
                    loginPassword: this.$md5(this.editpwdForm.newpwd2),//登录密码
                },
                'post',
                {}
              )
              .then((res) => {
                if(res.code == 0){
                  this.$router.push({
                    path: '/registerWin',
                    query: {
                      phone: this.business.managerPhone,
                      password: this.editpwdForm.newpwd
                    }
                  })
                  Notify({ type: 'success', message: '注册成功', duration: 1000});
                }else{
                  Notify({ type: 'danger', message: res.message});
                }
              }
            )
            setTimeout(
                function () {
                  this.flags = true;
                }.bind(this),
                500
              )
      }
    }
  }
}
</script>

<style scoped>
</style>